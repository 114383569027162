import React from "react";
import squad from "../../img/projects/squad.png";

class SquadGrid extends React.Component {
  render() {
    return (
      <div>
        <div className="work-box">
          <div className="work-img">
            <img src={squad} alt="" className="img-fluid" />
          </div>
          <div className="work-content">
            <div className="row">
              <div className="col-sm-12">
                <h2 className="w-title">Squad.</h2>
                <div className="w-more">
                  Squad is an app that helps connect NUS students with project group mates for their NUS modules. 
                  It can give you peace of mind, as you take control of the group formation process.
                  <br />
                  A design project done for CS3216: Software Product Engineering for Digital Markets. 
                  <br />
                  Through this exercise, we learnt to ideate and design an app that solves a real problem, 
                  and use empirical user testing to validate your idea and design.
                </div>
                <div className="external-link">
                  <a 
                    href="https://www.figma.com/file/5xrAtpnFp2A60MyTdaojMP/Final?node-id=95%3A8268" 
                    className="custom-underline"
                    target="_blank"
                    rel="noopener noreferrer">
                    → Check out the design prototype on Figma
                  </a>
                </div>
                <div className="external-link">
                  <a 
                    href="https://drive.google.com/file/d/1mWd5VoaIUGg6O5HNAMQSgyRGzhKrcE52/view?usp=sharing" 
                    className="custom-underline"
                    target="_blank"
                    rel="noopener noreferrer">
                    → Check out the design journal + report
                  </a>
                </div>
                <div className="external-link">
                  <a 
                    href="https://squad-cs3216.netlify.app" 
                    className="custom-underline"
                    target="_blank"
                    rel="noopener noreferrer">
                    → Check out the landing page
                  </a>
                </div>
                <div className="w-label">
                  <div className="label label-outlined">Figma</div>
                  <div className="label label-outlined">React</div>
                  <div className="label label-outlined">Typescript</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>);
  }
}

export default SquadGrid;
