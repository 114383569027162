import React from "react";
import cvwo from "../../img/projects/cvwo.png";

class CareCornerGrid extends React.Component {
  render() {
    return (
      <div>
        <div className="work-box">
          <div className="work-img">
            <img src={cvwo} alt="" className="img-fluid" />
          </div>
          <div className="work-content">
            <div className="row">
              <div className="col-sm-12">
                <h2 className="w-title">Computing for Voluntary Welfare Organisations</h2>
                <div className="w-more">
                  Collaborated with a team of 5 to maintain and enhance the Case Management System for CareCorner Singapore, a non-profit organisation. 
                  <br />
                  Migrated the frontend from HTML and jQuery to React, Redux and Typescript with the team.
                  <br />
                  Upgraded the report module and maintained database using Ruby on Rails and MySQL.
                </div>
                <div className="external-link">
                  <a 
                    href="https://www.comp.nus.edu.sg/~vwo/projects/2020-carecorner-cms.html" 
                    className="custom-underline"
                    target="_blank"
                    rel="noopener noreferrer">
                    → Find out more
                  </a>
                </div>
                <div className="w-label">
                  <div className="label label-outlined">React</div>
                  <div className="label label-outlined">Ruby on Rails</div>
                  <div className="label label-outlined">MySQL</div>
                  <div className="label label-outlined">Git</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>);
  }
}

export default CareCornerGrid;
