import React from "react";

class About extends React.Component {
  constructor() {
    super();
    this.state = {
      about_me: [
        {
          id: "first-p-about",
          content:
            "I am a final year Computer Science student from National University of Singapore, passionate about creating delightful changes through art, design and technology. "
        },
        {
          id: "second-p-about",
          content:
            "I believe in the power of interdisciplinarity, so I am constantly learning to build my technical skills and to combine them with my creative sense. I am committed to use my diverse skillset to make positive impacts. "
        },
      ]
    };
  }

  render() {
    return (
      <section id="about" className="about-mf sect-pt4 route">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="box-shadow-full">
                <div className="row">
                    <div className="about-me pt-4 pt-md-0 pl-4 pr-4">
                      <div className="title-box-2">
                        <h5 className="title-left">about me.</h5>
                      </div>
                      {this.state.about_me.map(content => {
                        return (
                          <p className="lead" key={content.id}>
                            {content.content}
                          </p>
                        );
                      })}
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
