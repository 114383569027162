import React from "react";
import cover from "../../img/projects/mcs-sqr-vid.png";

class InsShGrid extends React.Component {
  render() {
    return (
      <div>
        <div className="work-box">
          <div className="work-img">
            <img src={cover} alt="" className="img-fluid" />
          </div>
          <div className="work-content">
            <div className="row">
              <div className="col-sm-12">
                <h2 className="w-title">Senior High Survival Kit</h2>
                <div className="w-more">
                  A one-minute stop-motion video for orientation of new Senior High students. 
                  <br />
                  Scripted, directed, shot and edited by myself. 
                </div>
                <div className="external-link">
                  <a 
                    href="https://www.instagram.com/p/Bd0JKx9DEbz/" 
                    className="custom-underline"
                    target="_blank"
                    rel="noopener noreferrer">
                    → Watch the video on Instagram
                  </a>
                </div>
                <div className="w-label">
                  <div className="label label-outlined">Video Editing</div>
                  <div className="label label-outlined">Cinematography</div>
                  <div className="label label-outlined">Premiere Pro</div>
                  <div className="label label-outlined">Photoshop</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>);
  }
}

export default InsShGrid;
