import React from "react";
import dhour from "../../img/projects/dhour.jpg";

class DhourGrid extends React.Component {
  render() {
    return (
      <div>
        <div className="work-box">
          <div className="work-img">
            <img src={dhour} alt="" className="img-fluid" />
          </div>
          <div className="work-content">
            <div className="row">
              <div className="col-sm-12">
                <h2 className="w-title">D'Hour - 10 Year Anniversary Edition</h2>
                <div className="w-more">
                A magazine curated, designed and published by students to celebrate the 10th year anniversary of Publication Society, a student-run club. 
                <br />
                As the head designer, I led a team of 6 to produce the design of the magazine. 
                </div>
                <div className="external-link">
                  <a 
                    href="https://issuu.com/18y6c35zhangyifan/docs/_pubs_jan_18_ver_4.1" 
                    className="custom-underline"
                    target="_blank"
                    rel="noopener noreferrer">
                    → Check out the magazine on Issuu
                  </a>
                </div>
                <div className="w-label">
                  <div className="label label-outlined">Adobe InDesign</div>
                  <div className="label label-outlined">Adobe Illustrator</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>);
  }
}

export default DhourGrid;
