import React from "react";
import stripe from "../../img/projects/stripe.png";

class StripeGrid extends React.Component {
  render() {
    return (
      <div>
        <div className="work-box">
          <div className="work-img">
            <img src={stripe} alt="" className="img-fluid" />
          </div>
          <div className="work-content">
            <div className="row">
              <div className="col-sm-12">
                <h2 className="w-title">Software Engineer Intern @ Stripe</h2>
                <div className="w-more">
                  Migrated WeChat Pay endpoint from service written in Go to another service in Java, with upper layer traffic control implemented in Ruby. 
                  <br />
                  Wrote extensive tests including unit tests, integration tests, automated shadow testing for 100% of live traffic. 
                  <br />
                  Wrote comprehensive documentation to guide future migrations. 
                </div>
                <div className="w-label">
                  <div className="label label-outlined">Ruby</div>
                  <div className="label label-outlined">Java</div>
                  <div className="label label-outlined">Terraform</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>);
  }
}

export default StripeGrid;
