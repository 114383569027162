import React from "react";
import valebat from "../../img/projects/valebat.png";

class ValebatGrid extends React.Component {
  render() {
    return (
      <div>
        <div className="work-box">
          <div className="work-img">
            <img src={valebat} alt="" className="img-fluid" />
          </div>
          <div className="work-content">
            <div className="row">
              <div className="col-sm-12">
                <h2 className="w-title">Valebat</h2>
                <div className="w-more">
                Valebat is an action iPad game featuring randomized map generation, a unique spellcasting system and multiplayer support. 
                <br />
                A project done for CS3217. 
                </div>
                <div className="external-link">
                  <a 
                    href="https://github.com/Valebat/Valebat" 
                    className="custom-underline"
                    target="_blank"
                    rel="noopener noreferrer">
                    → Check out the project on GitHub
                  </a>
                </div>
                <div className="w-label">
                  <div className="label label-outlined">Swift</div>
                  <div className="label label-outlined">SpriteKit</div>
                  <div className="label label-outlined">GamePlayKit</div>
                  <div className="label label-outlined">Firebase</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>);
  }
}

export default ValebatGrid;
