import React from "react";
import wallofshame from "../../img/projects/wallofshame.png";

class WallOfShameGrid extends React.Component {
  render() {
    return (
      <div>
        <div className="work-box">
          <div className="work-img">
            <img src={wallofshame} alt="" className="img-fluid" />
          </div>
          <div className="work-content">
            <div className="row">
              <div className="col-sm-12">
                <h2 className="w-title">Wall of Shame</h2>
                <div className="w-more">
                  Wall of Shame is an application that empowers people to curb procrastination by challenging their friends in completing tasks. 
                  <br />
                  It is the modern-day, responsible equivalent of betting against your friends to see who can finish a challenge, with participants placing their reputations on the line. 
                  <br />
                  A product done for CS3216: Software Product Engineering for Digital Markets.
                  <br />
                  Everything done by a 4-student team, from ideation to prototyping to coding to launching. 
                </div>
                <div className="external-link">
                  <a 
                    href="https://github.com/Wall-Of-Shame/Wall-of-Shame" 
                    className="custom-underline"
                    target="_blank"
                    rel="noopener noreferrer">
                    → Check out the project on GitHub
                  </a>
                </div>
                <div className="external-link">
                  <a 
                    href="https://www.figma.com/file/iaSu6R1L0CvZdzNqrVjo6c/Hifi-Final-v3?node-id=0%3A1" 
                    className="custom-underline"
                    target="_blank"
                    rel="noopener noreferrer">
                    → Check out the prototype on Figma
                  </a>
                </div>
                <div className="external-link">
                  <a 
                    href="https://youtu.be/tJvxBC7jfjM" 
                    className="custom-underline"
                    target="_blank"
                    rel="noopener noreferrer">
                    → Watch promotional video
                  </a>
                </div>
                <div className="w-label">
                  <div className="label label-outlined">React</div>
                  <div className="label label-outlined">Typescript</div>
                  <div className="label label-outlined">Figma</div>
                  <div className="label label-outlined">Premiere Pro</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>);
  }
}

export default WallOfShameGrid;
