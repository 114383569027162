import React from "react";
import shopee from "../../img/projects/shopee.png";

class ShopeeGrid extends React.Component {
  render() {
    return (
      <div>
        <div className="work-box">
          <div className="work-img">
            <img src={shopee} alt="" className="img-fluid" />
          </div>
          <div className="work-content">
            <div className="row">
              <div className="col-sm-12">
                <h2 className="w-title">Backend Engineer Intern @ Shopee</h2>
                <div className="w-more">
                  Created a mock marketplace using Django, with Go TCP server for authentication and Nginx for load balancing. 
                  <br />
                  Built a notification feature for syncing between Google Sheet and internal platform, integrated with Mattermost using Go, with extensive tests written. 
                  <br />
                  Developed a Go linter to format todo comments across codebase. 
                </div>
                <div className="w-label">
                  <div className="label label-outlined">Golang</div>
                  <div className="label label-outlined">Python</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>);
  }
}

export default ShopeeGrid;
