import React from "react";
import flag from "../../img/projects/flag.png";

class FlagGrid extends React.Component {
  render() {
    return (
      <div>
        <div className="work-box">
          <div className="work-img">
            <img src={flag} alt="" className="img-fluid" />
          </div>
          <div className="work-content">
            <div className="row">
              <div className="col-sm-12">
                <h2 className="w-title">Flag Day 2020 (Residential College 4)</h2>
                <div className="w-more">
                  Led a team of 4 to produce graphic design, event and social media coverage for FLAG day. 
                  <br />
                  Created publicity content for Instagram promotion. 
                  <br />
                  Liaised with our community beneficiary, PAVE (Promoting Alternatives to Violence), to execute fundraising events at college level. 
                </div>
                <div className="external-link">
                  <a 
                    href="https://instagram.com/rc4flag" 
                    className="custom-underline"
                    target="_blank"
                    rel="noopener noreferrer">
                    → View Instagram Page
                  </a>
                </div>
                <div className="w-label">
                  <div className="label label-outlined">Illustrator</div>
                  <div className="label label-outlined">Photoshop</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>);
  }
}

export default FlagGrid;
