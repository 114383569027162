import React from "react";
import $ from "jquery";
import CareCornerGrid from "./projects/carecorner";
import SourceGrid from "./projects/source";
import SdmaGrid from "./projects/sdma";
import InsShGrid from "./projects/ins-sh";
import FlagGrid from "./projects/flag";
import ValebatGrid from "./projects/valebat";
import ZoombaGrid from "./projects/zoomba";
import DhourGrid from "./projects/dhour";
import WallOfShameGrid from "./projects/wallofshame";
import SquadGrid from "./projects/squad";
import LinguaGrid from "./projects/lingua";
import FriendsGoOutGrid from "./projects/friendsgoout";
import StripeGrid from "./projects/stripe";
import ShopeeGrid from "./projects/shopee";

class PortfolioTab extends React.Component {

  componentDidMount() {
    $("#tabs a:first").tab('show');
  }

  render() {
    return (
      <nav
        className="navbar navbar-b navbar-container navbar-expand-md"
        id="portfolio-tab"
      >
        <div className="container align-top p-1 mt-2 mr-2">
          <div className="row">
            <div className="col-sm-12 pt-1 pb-3">
              <ul id="tabs" className="nav navbar-nav">
                <li className="nav-item">
                  <a className="nav-link active" href="#code" data-toggle="tab">
                    I code.
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#design" data-toggle="tab">
                    I design.
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link " href="#shoot" data-toggle="tab">
                    I shoot.
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link " href="#care" data-toggle="tab">
                    I care.
                  </a>
                </li>
                <li></li>
              </ul>
            </div>
            <div className="tab-content col-sm-12 pt-2">
              <div className="tab-pane fade show active" id="code">
                <div className="row">
                  <div className="col-sm-6">
                    <StripeGrid />
                    <WallOfShameGrid />
                    <ValebatGrid />
                  </div>
                  <div className="col-sm-6">
                    <ShopeeGrid />
                    <ZoombaGrid />
                    <CareCornerGrid />
                    <SourceGrid />
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="design">
              <div className="row">
                  <div className="col-sm-6">
                    <SquadGrid />
                    <LinguaGrid />
                    <FlagGrid />
                  </div>
                  <div className="col-sm-6">
                    <WallOfShameGrid />
                    <FriendsGoOutGrid />
                    <DhourGrid />
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="shoot">
                <div className="row">
                  <div className="col-sm-6">
                    <SdmaGrid />
                    <WallOfShameGrid />
                  </div>
                  <div className="col-sm-6">
                    <InsShGrid />
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="care">
                <div className="row">
                  <div className="col-sm-6">
                    <CareCornerGrid />
                  </div>
                  <div className="col-sm-6">
                    <FlagGrid />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default PortfolioTab;
