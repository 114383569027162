import React from "react";
import imageOverlay from "../img/cloud2.jpg"

class Contact extends React.Component {
  render() {
    return (
      <section
        className="paralax-mf footer-paralax bg-image sect-mt4 route"
        style={{ backgroundImage: "url(" + imageOverlay + ")" }}
      >
        <div className="overlay-mf"></div>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="contact-mf">
                <div id="contact" className="box-shadow-full">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="title-box-2 pt-4 pt-md-0">
                        <h5 className="title-left">get in touch.</h5>
                      </div>
                      <div className="more-info full-link">
                        <p className="lead">
                          Thank you for dropping by! 
                          <br />
                          If you'd like to get in touch, feel free to drop me an email at&nbsp;
                          <a 
                            href="mailto:yifanzhang.dev@gmail.com" 
                            className="full-underline" 
                            target="_blank"
                            rel="noopener noreferrer">
                            yifanzhang.dev@gmail.com
                          </a>
                          , or simply click on the icons below for my social media goodness :) 
                          <br />
                          I'd love to hear from you!
                        </p>
                      </div>
                      <div className="connects">
                        <a
                          href="https://www.linkedin.com/in/yifan-zhang-nus"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fa fa-linkedin fa-2x" title="LinkedIn"></i>
                        </a>
                        <a
                          href="https://github.com/theyifan"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fa fa-github fa-2x" title="GitHub"></i>
                        </a>
                        <a
                          href="https://www.instagram.com/containerism"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fa fa-instagram fa-2x" title="Instagram"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer>
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
              </div>
            </div>
          </div>
        </footer>
      </section>
    );
  }
}

export default Contact;
