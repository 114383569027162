import React from "react";
import zoomba from "../../img/projects/zoomba.png";

class ZoombaGrid extends React.Component {
  render() {
    return (
      <div>
        <div className="work-box">
          <div className="work-img">
            <img src={zoomba} alt="" className="img-fluid" />
          </div>
          <div className="work-content">
            <div className="row">
              <div className="col-sm-12">
                <h2 className="w-title">ZoomBa</h2>
                <div className="w-more">
                A video conference presentation script renderer / teleprompter. Desktop app powered by Electron. 
                <br />
                A project built for Hack&Roll 2021.
                </div>
                <div className="external-link">
                  <a 
                    href="https://github.com/nus-zoomers/ZoomBa" 
                    className="custom-underline"
                    target="_blank"
                    rel="noopener noreferrer">
                    → Check out the project
                  </a>
                </div>
                <div className="w-label">
                  <div className="label label-outlined">Electron</div>
                  <div className="label label-outlined">React</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>);
  }
}

export default ZoombaGrid;
