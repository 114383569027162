import React from "react";
import PortfolioTab from "./portfolio-tab.jsx";

class Portfolio extends React.Component {
  render() {
    return (
      <section id="work" className="portfolio-mf sect-pt4 route">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="title-box text-left">
                <h3 className="title-a">portfolio.</h3>
                <p className="subtitle-a">
                  some projects that I have done along the journey.
                </p>
                <div className="line-mf-left"></div>
              </div>
            </div>
          </div>
          <PortfolioTab />
        </div>
      </section>
    );
  }
}

export default Portfolio;
