import React from "react";
import friendsgoout from "../../img/projects/friendsgoout.png";

class FriendsGoOutGrid extends React.Component {
  render() {
    return (
      <div>
        <div className="work-box">
          <div className="work-img">
            <img src={friendsgoout} alt="" className="img-fluid" />
          </div>
          <div className="work-content">
            <div className="row">
              <div className="col-sm-12">
                <h2 className="w-title">Friends Go Out</h2>
                <div className="w-more">
                  An interactive solution that helps friend-groups to straighten out all the administrative issues 
                  of planning outings in a pleasant and effective way.
                  <br />
                  Group project for NUS CS3240 Interaction Design Module. 
                  <br />
                </div>
                <div className="external-link">
                  <a 
                    href="https://www.figma.com/file/NWGrBXAPCCksTUUVpKcYKG/Hifi-Final?node-id=0%3A1" 
                    className="custom-underline"
                    target="_blank"
                    rel="noopener noreferrer">
                    → Check out the design prototype on Figma
                  </a>
                </div>
                <div className="external-link">
                  <a 
                    href="https://uxfol.io/p/45cd60bd/037423df" 
                    className="custom-underline"
                    target="_blank"
                    rel="noopener noreferrer">
                    → Check out the design journal + portfolio
                  </a>
                </div>
                <div className="w-label">
                  <div className="label label-outlined">Figma</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>);
  }
}

export default FriendsGoOutGrid;
