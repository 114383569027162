import React from "react";
import source from "../../img/projects/source.jpg";

class SourceGrid extends React.Component {
  render() {
    return (
      <div>
        <div className="work-box">
          <div className="work-img">
            <img src={source} alt="" className="img-fluid" />
          </div>
          <div className="work-content">
            <div className="row">
              <div className="col-sm-12">
                <h2 className="w-title">Source Academy</h2>
                <div className="w-more">
                Collaborated with a team of 5 for the experimental re-write of the core of the frontend using React, including architecture prototyping, mobile responsiveness design. 
                </div>
                <div className="external-link">
                  <a 
                    href="https://github.com/source-academy/journey-frontend" 
                    className="custom-underline"
                    target="_blank"
                    rel="noopener noreferrer">
                    → Find out more
                  </a>
                </div>
                <div className="w-label">
                  <div className="label label-outlined">React</div>
                  <div className="label label-outlined">HTML</div>
                  <div className="label label-outlined">Git</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>);
  }
}

export default SourceGrid;
